import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                The Bitcoin Wizard Ordinal is a unique digital collection that pays tribute and celebrates the 10 year Anniversary to the character that played a crucial role in the rise of Bitcoin. Originally created in 2013 by mavensbot, the ordinal presents an exclusive and creative rendering of the Bitcoin Wizard. The Wizard is showcased in his iconic hat, long beard, and carrying his Bitcoin staff, symbolizing his guardianship of this digital currency.
            </p><p>
                The MS Paint rendering of the Bitcoin Wizard is a one-of-a-kind work of art, capturing the character's essence and the charm of the crypto world. Owning this NFT means having a piece of history in your collection, being an early adopter of the Bitcoin movement, and experiencing a sense of nostalgia that only the Bitcoin Wizard can bring.
            </p><p>
                This limited-edition NFT is only available in a few numbers, and each is individually numbered, making it a valuable piece of digital art. The Bitcoin Wizard Ordinal is a must-have for those who appreciate the historical significance of Bitcoin, want to be part of its early adopters' community, and seek to relive the nostalgia of the past.
            </p><p>
                In summary, the Bitcoin Wizard Ordinal NFT is a unique digital collectible that brings together art, history, and nostalgia in one package, making it an excellent addition to any collection.
            </p>
        </>
    )
}

export default Lore